<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<!-- <style type="text/css" scoped>
  .custom-file-label::after {
    display: none;
  }
</style> -->
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import CertHeader from "./cert-header";
  import {
    addZsk,
    editZsk,
    getZskInfo
  } from "@/api/zhengshu/manage.js"
  import {
    getExamEasy,
  } from "@/api/admin/exam/examRecord.js"
  import {easyList, SecondEasyList} from "@/api/admin/base/mainType";

  /**
   * 证书库导入--基本信息
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      CertHeader


    },
    data() {
      return {
        title: "证书库导入",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        years: [],
        examList: [],
        secondList: [],
        classList: [],
        sid: "",
        ksmc: "",
        ksbmbh: "",
        addForm: {
          nf: new Date().getFullYear(),
          zskmc: "",
          cxtj: "",
          lbbm: "",
          flbm: "",
          lbmc: "",
          flmc: ""
        },
        checkForm: [],
        columns: [{
            name: "姓名",
            val: "ksxm"
          },
          {
            name: "证件号码",
            val: "zjhm"
          },
          {
            name: "订单号",
            val: "ddh"
          }
        ]
      };
    },
    methods: {
      //获取当前年份开始的前后几年
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      // 获取考试
      getExamList() {
        getExamEasy({
          ksnf: this.addForm.nf
        }).then(res => {
          if (res.status) {
            this.examList = res.data
          }
        })
      },
      // 添加
      addItem() {
        let fromData = JSON.parse(JSON.stringify(this.addForm))
        if (!this.addForm.ksbmbh) {
          fromData.ksmc = this.addForm.zskmc
        }
        addZsk(fromData).then(res => {
          if (res.status) {
            this.zskid = res.data.zskid
            this.ksbmbh = res.data.ksbmbh
            this.success()
          }
        })
      },
      // 修改
      editItem() {
        let fromData = JSON.parse(JSON.stringify(this.addForm))
        if (!this.addForm.ksbmbh) {
          fromData.ksmc = this.addForm.zskmc
        }
        editZsk({
          ...fromData,
          zskid: this.zskid
        }).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        window.location.href = "/admin/certImportMatch?zskid=" + this.zskid;
      },
      // 获取详情
      getDeatails(zskid) {
        getZskInfo(zskid).then(res => {
          if (res.status) {
            this.addForm = res.data
            this.ksmc = res.data.ksmc ? res.data.ksmc : ""
            this.title2 = res.data.ksmc ? res.data.ksmc : ""
            if (res.data.cxtj) {
              this.checkForm = res.data.cxtj.split(",")
            } else {
              this.checkForm = []
            }
          }
        })

      },
      test() {
        let str = this.checkForm.join(",")
        if (str) {
          this.addForm.cxtj = str
          console.log(this.addForm.cxtj)
        }

      },
      // 提交时分流
      submit() {
        if (this.zskid) {
          this.editItem()
        } else {
          this.addItem()
        }
      },
      /*      // 考试类别
            getClassListPage() {
              easyList().then(res => {
                if (res.status) {
                  this.classList = res.data
                }
              })
            },
            // 二级分类
            getSecondList(params) {
              SecondEasyList(params).then(res => {
                if (res.status) {
                  this.secondList = res.data
                }
              })
            },
            getflmc() {
              let val = this.addForm.flbm
              let obj = this.secondList.find(k => {
                return k.flbm === val
              })
              this.addForm.flmc = obj.flmc

            },
            getlbmc() {
              let val = this.addForm.lbbm
              let obj = this.classList.find(k => {
                return k.lbbm === val
              })
              this.addForm.lbmc = obj.lbmc
              this.getSecondList({
                lbbm: val
              });
            }, */
      getKsmc() {
        let val = this.addForm.ksbmbh

        let obj = this.examList.find(k => {
          return k.ksbmbh === val
        })
        if (obj) {
          this.addForm.zskmc = obj.ksmc
        } else {
          this.addForm.zskmc = ""
        }
      },
    },
    mounted() {
      this.getYear()
      this.getExamList()
      // this.getClassListPage()
      // this.getSecondList()
      if (this.zskid) {
        this.getDeatails(this.zskid)
      }
    },
    created() {
      this.ksbmbh = this.$route.query["ksbmbh"] ? this.$route.query["ksbmbh"] : "";
      this.zskid = this.$route.query["zskid"] ? this.$route.query["zskid"] : "";

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <CertHeader ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 460px;">
          <div class="card-body">
            <div class="outerCotent mt-3 ">
              <div class="msgContent flexList fs-xs">
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">选择年份</div>
                  <div class="msgVal selectShow flexList">
                    <select class="form-control w-30 msgValInput" v-model="addForm.nf" @change="getExamList()">
                      <option>年份 </option>
                      <option v-for="(item, i) in years" :value="item.value" :key="i">{{item.value}}</option>
                    </select>

                  </div>
                </div>
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">选择考试</div>
                  <div class="msgVal selectShow  flexList w-33">
                    <select class="form-control w-30 msgValInput" v-model="addForm.ksbmbh" @change="getKsmc">
                      <option disabled>请选择考试 </option>
                      <option value="">其他</option>
                      <option v-for="(item, i) in examList" :value="item.ksbmbh" :key="i">{{item.ksmc}}</option>
                    </select>
                  </div>
                </div>
                <!--  <div class="flexList w-100 msgLabel " v-if="!addForm.ksbmbh">
                  <div class="msgName ">考试名称</div>
                  <div class="msgVal selectShow  flexList ">
                    <input type="text" name="" id="" maxlength="50" value="" v-model="ksmc" placeholder="请输入考试名称"
                      class="form-control w-30 h30 " />
                  </div>
                </div>


                  <div class="flexList w-100 msgLabel " v-if="!addForm.ksbmbh">
                    <div class="msgName">所属类别</div>
                    <div class="msgVal selectShow  flexList ">
                      <select name="lbbm" class="form-control form-select w-30 pt-1" v-model="addForm.lbbm" @change="getlbmc">
                        <option value="" disabled>请选择所属类别</option>
                        <option :value="item.lbbm" v-for="(item,index) in classList" :key="index">{{item.lbmc}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel " v-if="!addForm.ksbmbh">
                    <div class="msgName">所属分类</div>
                    <div class="msgVal selectShow  flexList ">
                      <select name="" class="form-control form-select w-30 pt-1" v-model="addForm.flbm" @change="getflmc">
                        <option value="" disabled>请选择分类</option>
                        <option :value="item.flbm" v-for="(item,index) in secondList" :key="index">{{item.flmc}}</option>
                      </select>
                    </div>
                  </div> -->

                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">证书库名称</div>
                  <div class="msgVal selectShow  flexList ">
                    <input type="text" name="" id="" maxlength="50" value="" v-model="addForm.zskmc"
                      placeholder="请输入证书库名称" class="form-control w-30 h30 " />
                  </div>
                </div>
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">查询条件</div>
                  <div class="msgVal   flexList ">
                    <el-checkbox-group v-model="checkForm" @change="test">
                      <el-checkbox v-for="(item,index) in columns" :key="index" :value="item.val" :label="item.val"
                        class="mb-0">
                        {{item.name}}
                      </el-checkbox>
                    </el-checkbox-group>

                  </div>
                </div>
                <div class="flexList w-100 msgLabel" style="height: 60px;">
                  <div class="msgName"></div>
                  <div class="msgVal flexList">
                    <button class="btn btn-info h35 mr-2 sub-btn" @click="submit">点击进入下一步</button>
                    <a href="javascript:window.location='/admin/certificateManage'"><button
                        class="btn btn-secondary h35 mr-2 ">返回列表</button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
