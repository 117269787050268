<style type="text/css" scoped>
.score-head-bottom {
  cursor: pointer;
}
</style>
<script>
export default {
  data() {
    return {
      sid: "",
      isActive: 1,
      pathArr: [{
        index: 1,
        url: "/admin/certImport",
        icon: "icon-riLine-edit-line",
        name: "证书库基本信息",
      },
        {
          index: 2,
          url: "/admin/certImportMatch",
          icon: "icon-riLine-upload-2-line",
          name: "数据上传及匹配",
        },
        {
          index: 3,
          url: "/admin/cerImportLogistics",
          icon: "icon-calendar_clock",
          name: "考区以及物流收费绑定",
        },
        {
          index: 4,
          url: "/admin/certImportCheck",
          icon: "icon-newspaper-o",
          name: "证书库检查",
        },
      ]
    }
  },
  mounted() {
    this.isActive = window.location.pathname

  },
  methods: {
    getUrl(url) {
      let query = this.$route.query
      let zskid = query.zskid
      // let ksbmbh = query.ksbmbh
      if (zskid) {
        this.$router.push({
          path: url,
          query: {
            zskid: zskid
          }
        })
      } else {
        this.$message({
          type: "warning",
          message: "请先添加证书库",
        });
      }
    }
  },

};
</script>

<style>
/* @import "~@/assets/css/enroll-form.css"; */
</style>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="flexList ">

            <div class="d-flex score-header" v-for="obj in pathArr" :key="obj.index">
              <div class="score-tips" :class="{active:isActive==obj.url}">
                <div class="score-head-top flexList justify-content-center">
                  <i :class="`${obj.icon} mr-2 iconfont `"></i>
                  <div class="fw600">步骤{{obj.index}}</div>
                </div>
                <a @click="getUrl(obj.url,{sid})">
                  <div class="score-head-bottom">{{obj.name}} </div>
                </a>
              </div>
              <div class="score-head-right">
                <i class=" mr-2 iconfont icon-angle-double-right"></i>
              </div>

            </div>

            <div class="d-flex   score-header">
              <div class="score-tips">
                <div class="score-head-top flexList justify-content-center">
                  <i class=" mr-2 iconfont icon-data"></i>
                  <div class="fw600">步骤5</div>
                </div>
                <div class="score-head-bottom">完成上传 </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>

</template>
